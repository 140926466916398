import { useSelector } from 'react-redux'

const identity = (val) => {
  return val
}

export const createAction = (type, payloadCreator, metaCreator) => {
  const finalPayloadCreator =
    typeof payloadCreator === 'function'
      ? payloadCreator
      : payloadCreator && payloadCreator.noPayload
      ? null
      : identity

  const actionCreator = (...args) => {
    const hasError = args[0] instanceof Error

    const action = {
      type,
    }

    let payload = null
    if (finalPayloadCreator !== null) {
      payload = hasError ? args[0] : finalPayloadCreator(...args)
    }
    if (!(payload === null || payload === undefined)) {
      action.payload = payload
    }

    if (hasError || payload instanceof Error) {
      action.error = true
    }

    if (typeof metaCreator === 'function') {
      action.meta = metaCreator(...args)
    }

    return action
  }

  actionCreator.toString = () => type.toString()
  actionCreator.type = type.toString()

  return actionCreator
}

export const action = (type, payload) => {
  return { type, payload }
}

export const asyncAction = (config) => {
  let event = {
    name: config.baseName,
    start: config.baseName + '_START',
    success: config.baseName + '_SUCCESS',
    error: config.baseName + '_ERROR',
  }

  let request = (type, isPending, error) => (payload) => ({
    type,
    payload,
    request: {
      id: config.baseName,
      isPending,
      error: error ? payload : null,
    },
  })

  let dispatchers = {
    start: request(event.start, true),
    success: request(event.success, false),
    error: request(event.error, false, true),
  }

  let action = config.action(dispatchers)

  action.event = event

  return action
}

export const pollingAction = (action, frequency) => {
  let actionInterval = null

  let isActive = () => !!actionInterval

  let stop = () => {
    if (actionInterval) {
      clearInterval(actionInterval)
      actionInterval = null
    }
  }

  let start = (dispatch, getState) => {
    stop()
    action(dispatch, getState)
    actionInterval = setInterval(() => action(dispatch, getState), frequency)
  }

  return { start, stop, isActive }
}

// Middleware to replace Error instances with their messages
export const parseErrorMiddleware = (store) => (next) => (action) => {
  if (action.type && action.payload instanceof Error) {
    action = { ...action, payload: action.payload.message }
  }

  return next(action)
}

export function requestReducer(state = {}, action) {
  if (action.request) {
    return {
      ...state,
      [action.request.id]: {
        isPending: action.request.isPending,
        error: action.request.error,
      },
    }
  }

  return state
}

export const selectIsAnyRequestPending = (state) => {
  return Object.keys(state.request).some((key) => {
    let action = state.request[key] || {}
    return action.isPending
  })
}

export const selectIsRequestPending = (state, action) => {
  // useSelector just provides states
  // use partial application to simplify component signature
  // state === action in this case
  // usage: useSelector(selectIsRequestPending(action))
  if (!action && state.event) return (s) => selectIsRequestPending(s, state)
  return (state.request[action.event.name] || {}).isPending === true
}

export const selectRequestError = (state, action) => {
  if (!action && state.event) return (s) => selectRequestError(s, state)
  return (state.request[action.event.name] || {}).error
}

export function useAsyncStatus(action) {
  const pending = useSelector((state) => selectIsRequestPending(state, action))
  const error = useSelector((state) => selectRequestError(state, action))
  return [pending, error]
}
